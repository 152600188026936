<template>
  <v-dialog v-model="show" transition="fade-transition" persistent>
    <div
      @click.stop="show = false"
      class="text-right pt-3 pr-3"
      style="position: sticky; top: 0px; right: 0px; width; z-index: 1; background-color: black;"
    >
      <v-icon class="cursor-pointer close-icon white--text">mdi-close</v-icon>
    </div>

    <v-card elevation="0" tile color="black">
      <v-row no-gutters justify="center">
        <v-col cols="11">
          <v-row no-gutters>
            <v-col class="text-center" cols="12">
              <span class="title-modal white--text"
                >{{privacySingleton.title}}</span
              >
            </v-col>

            <v-col class="my-5 my-sm-10 mx-3 mx-sm-12">
              <span class="text-modal white--text" v-html="privacySingleton.text"
                ></span
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    visible: { type: Boolean, required: true }
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          return this.$emit('close')
        }
      }
    }
  },
  data () {
    return {
      privacySingleton: {}
    }
  },
  beforeCreate () {
    fetch(
      'https://www.landing-xonoro-cms.actstudio.xyz/api/singletons/get/policyPrivacy'
    )
      .then((data) => data.json())
      .then((data) => (this.privacySingleton = data))
      .catch((error) => console.log(error.message))
  }
}
</script>
